<template>
  <div class="home">
    欢迎使用，于都县中医院互联网健康服务平台
  </div>

</template>

<script>
export default {
  name: "index"
};
</script>

<style lang="scss" scoped>
.home{
  padding: 40px;
  font-size: 30px;
  font-weight: bold;
}
</style>